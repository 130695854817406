import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Loader } from './comps/loader';
import reportWebVitals from './reportWebVitals';

import './style/index.scss';


const App = React.lazy(() => import('./app'));


ReactDOM.render(
  <Suspense fallback={<div className="page-loader"><Loader color="green" /></div>}><App /></Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
